import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const UltimateBubbles = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginBottom: '3%' }}>
                <Image
                    style={{
                        width: '850px',
                    }}
                    src={require('../assets/ultimatebubbles-logo.png')}
                />
            </Row>
            <Row style={{ marginTop: 15 }}>
            <p>
                <h4>ULTIMATE BUBBLES - THE ULTIMATE BUBBLE PACK!</h4>
                Bring your scene to life with <b>customizable bubble materials</b> and <b>dynamic Niagara systems</b>.</p>
            <div>
                <div>
                    <h5>HOW TO USE:</h5>
                    <p>
                        <ul>
                            <li>Either create custom bubble material instances or use the base materials.</li>
                            <li>Add the bubble materials or Niagara systems to your scene.</li>
                            <li>Press Play!</li>
                        </ul>
                    </p>
                </div>
                <div>
                    <h5>EASY TO CUSTOMIZE:</h5>
                    <p>Create the perfect bubble for your scene using exposed parameters in the material instances:</p>
                    <p>
                        <ul>
                            <li>Choose from <b>Soapy, Realistic, Rainbow, and Mobile</b> base bubble materials.</li>
                            <li>Adjust <b>colors, soapiness, bounce, transparency, and shimmer to match your vision.</b></li>
                        </ul>
                    </p>
                </div>
                <div>
                    <h5>NIAGARA SYSTEMS:</h5>
                    <p>Customize the Niagara systems to create even more unique effects.</p>
                    <p>
                        <ul>
                            <li><b>Bubble Fountain</b>: A mesmerizing spray of bubbles shooting from the ground.</li>
                            <li><b>Bubbles in the Wind</b>: Realistic floating bubbles with dynamic wind interaction.</li>
                        </ul>
                    </p>
                </div>
                <div>
                    <h5>BONUS CONTENT:</h5>
                    <p>Customize the Niagara systems to create even more unique effects.</p>
                    <p>
                        <ul>
                            <li><b>Bubble Blower Blueprints</b>: Add interactive bubble blowers to your scene for extra fun!</li>
                            <li><b>Mobile Optimized Material</b>: Perfect for performance-limited scenes, ensuring smooth and stunning visuals on mobile devices.</li>
                            <li><b>Demo Scene</b>: Includes scene showcasing all materials and Niagara systems to see their full potential.</li>
                        </ul>
                    </p>
                </div>
                <div>
                    <h5>The package includes:</h5>
                    <p>
                        <ul>
                            <li><b>4 Bubble Materials</b>: Soapy, Realistic, Rainbow, Mobile</li>
                            <li><b>2 Niagara Systems</b>: Bubble Fountain, Bubbles Blowing in the Wind</li>
                            <li><b>Bubble Blower Blueprints</b></li>
                            <li><b>Demo Scene</b> with all features pre-set</li>
                        </ul>
                    </p>
                </div>
            </div>
                <p>
                    <a href='https://www.fab.com/'>Ultimate Bubbles - FAB Marketplace</a>
                </p>
            </Row>
            {/* <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row',  display: 'inline-block'}}>
                <h3>Related Files</h3>
                <a href='https://www.herocode.us/mobilestatustoolkit/mobilestatustoolkit.pdf'>Mobile Status Toolkit Documentation</a>
            </Row> */}
        </Container>
    )
}

export default UltimateBubbles